import { cn } from "@daybridge/cn"
import { DateTime } from "luxon"
import React, { cache } from "react"

interface TerminatorLineCoordinates {
  latitudeDegrees: number
  longitudeDegrees: number
}

type MapTerminatorLineProps = Omit<
  React.SVGAttributes<SVGSVGElement>,
  "children"
> & {
  time: DateTime
}

/**
 * A terminator line is the line that separates the day side of the Earth from the night side, or
 * a sunrise/sunset boundary.
 *
 * The MapTerminatorLine component takes in an array of coordinates that define the terminator line,
 * as well as the declination of the sun. It then interpolates the latitudes at the edges
 * of the terminator line, creates a new array of coordinates that includes the interpolated latitudes,
 * and calculates the terminator line path using the new coordinates. Finally, it returns an SVG
 * element with the terminator line path.
 */
const MapTerminatorLineFn = React.forwardRef(
  (props: MapTerminatorLineProps, ref: React.ForwardedRef<SVGSVGElement>) => {
    const { time, ...rest } = props

    const declinationDegrees = calculateDeclination(time.toUTC().ordinal)
    const coordinates = calculateTerminatorCoordinates(
      declinationDegrees,
      time.toUTC().hour + time.toUTC().minute / 60,
    )

    // Interpolate latitudes at the edges
    const leftMost = coordinates.reduce((prev, curr) =>
      curr.longitudeDegrees < prev.longitudeDegrees ? curr : prev,
    )
    const rightMost = coordinates.reduce((prev, curr) =>
      curr.longitudeDegrees > prev.longitudeDegrees ? curr : prev,
    )

    const leftEdgeLat =
      leftMost.latitudeDegrees +
      ((180 + leftMost.longitudeDegrees) *
        (coordinates[1].latitudeDegrees - leftMost.latitudeDegrees)) /
        (coordinates[1].longitudeDegrees - leftMost.longitudeDegrees)
    const rightEdgeLat =
      rightMost.latitudeDegrees +
      ((180 - rightMost.longitudeDegrees) *
        (rightMost.latitudeDegrees -
          coordinates[coordinates.length - 2].latitudeDegrees)) /
        (rightMost.longitudeDegrees -
          coordinates[coordinates.length - 2].longitudeDegrees)

    const newCoordinates = [
      { latitudeDegrees: leftEdgeLat, longitudeDegrees: -180 },
      ...coordinates,
      { latitudeDegrees: rightEdgeLat, longitudeDegrees: 180 },
    ]

    // Generate SVG path
    let terminatorPath = `M ${newCoordinates[0].longitudeDegrees + 180} ${
      90 - newCoordinates[0].latitudeDegrees
    }`

    for (let i = 1; i < newCoordinates.length; i++) {
      terminatorPath += ` L ${newCoordinates[i].longitudeDegrees + 180} ${
        90 - newCoordinates[i].latitudeDegrees
      }`
    }

    // Extend the path to the edges of the SVG, creating a closed shape
    const terminatorPathFilled =
      terminatorPath +
      (declinationDegrees > 0 ? ` L 360 180 L 0 180 Z` : ` L 360 0 L 0 0 Z`)

    return (
      <svg ref={ref} viewBox="0 0 360 180" preserveAspectRatio="none" {...rest}>
        <path
          d={terminatorPathFilled}
          fill="black"
          className={cn("hidden dark:block")}
          fillOpacity={0.7}
        />
        <path
          d={terminatorPathFilled}
          fill="black"
          className={cn("dark:hidden")}
          fillOpacity={0.2}
        />
        <path
          d={terminatorPath}
          fill="none"
          strokeWidth={1}
          stroke="oklch(var(--color-adaptive-tint) var(--hue) / 0.2)"
        />
      </svg>
    )
  },
)
MapTerminatorLineFn.displayName = "MapTerminatorLine"

export const MapTerminatorLine = React.memo(
  MapTerminatorLineFn,
) as typeof MapTerminatorLineFn

/**
 * Calculates the declination of the sun for a given date. This function takes in a DateTime
 * object representing a date, and calculates the declination of the sun for that date
 * using a series of mathematical formulas. It then returns the declination in degrees.
 * The function includes inline comments that explain each step of the calculation.
 *
 * Results are cached as the declination is the same for every ordinal day of the year.
 *
 * @param ordinalDay The ordinal day of the year.
 * @returns The declination of the sun in degrees.
 */
export const calculateDeclination = cache((ordinalDay: number): number => {
  // Calculate the mean anomaly of the sun
  // The mean anomaly is the angular distance between the perihelion
  // (closest point to the sun) and the current position of the sun.
  const M = -3.6 + 0.9856 * ordinalDay

  // Calculate the true anomaly of the sun
  // The true anomaly is the angular distance between the perihelion
  // and the current position of the sun, as seen from the center of the ellipse
  const V = M + 1.916 * Math.sin((M * Math.PI) / 180)

  // Calculate the ecliptic longitude of the sun
  // The ecliptic longitude is the angular distance between the vernal equinox
  // (the point where the sun crosses the celestial equator from south to north)
  // and the current position of the sun, as seen from the center of the ellipse.
  const L = V + 102.9

  // Calculate the declination of the sun
  // The declination is the angular distance between the equator and
  // the current position of the sun, as seen from the center of the ellipse.
  const d =
    22.8 * Math.sin((L * Math.PI) / 180) +
    0.6 * Math.sin((2 * L * Math.PI) / 180) ** 3

  // Return the declination in degrees
  return -d
})

/**
 * Calculates the coordinates of the day/night terminator line for a given timezone. It takes in a
 * declinationDegrees number and an hour number, and calculates the coordinates of the day/night terminator.
 *
 * @param declinationDegrees The declination of the sun in degrees.
 * @param hour The hour offset from UTC.
 * @returns An array of objects containing the latitude and longitude of each point on the terminator line.
 */
export const calculateTerminatorCoordinates = cache(
  (declinationDegrees: number, hour: number): TerminatorLineCoordinates[] => {
    // Convert the declination to radians
    const bRadians = (declinationDegrees * Math.PI) / 180

    // Calculate the longitude of the terminator line
    let l = 180 - hour * 15
    if (l < -180) {
      l += 360
    } else if (l > 180) {
      l -= 360
    }
    const lRadians = (l * Math.PI) / 180

    // Calculate the coordinates of the terminator line
    const results = []
    for (let p = 0; p < 360; p += 1) {
      const pRadians = (p * Math.PI) / 180

      // Calculate the latitude of the terminator line
      const latitude = Math.asin(Math.cos(bRadians) * Math.sin(pRadians))

      // Calculate the x and y coordinates of the terminator line
      const x =
        -Math.cos(lRadians) * Math.sin(bRadians) * Math.sin(pRadians) -
        Math.sin(lRadians) * Math.cos(pRadians)
      const y =
        -Math.sin(lRadians) * Math.sin(bRadians) * Math.sin(pRadians) +
        Math.cos(lRadians) * Math.cos(pRadians)

      // Calculate the longitude of the terminator line
      const longitude = Math.atan2(y, x)

      // Convert the latitude and longitude to degrees
      const latitudeDegrees = (latitude * 180) / Math.PI
      let longitudeDegrees = (longitude * 180) / Math.PI
      if (longitudeDegrees > 180) {
        longitudeDegrees -= 360
      }

      // Add the coordinates to the results array
      results.push({ latitudeDegrees, longitudeDegrees })
    }

    // Sort the results by longitude ascending
    return results.sort((a, b) => a.longitudeDegrees - b.longitudeDegrees)
  },
)
