"use client"

import { GraphQLClient } from "graphql-request"
import React, { createContext, useContext, useState } from "react"

interface GraphQLClientProviderProps {
  // `serverUrl` is the URL of the GraphQL server.
  serverUrl: string

  // Client options, such as a set of headers to attach to each request.
  options?: RequestInit

  // The children to which the GraphQL client should be made available
  // via the `useGraphQLClient` hook.
  children?: React.ReactNode
}

// `clientContext` is a React context object that stores a GraphQLClient instance.
const clientContext = createContext<GraphQLClient | undefined>(undefined)
export const useGraphQLClient = () => useContext(clientContext)

/**
 * `GraphQLClientProvider` makes a GraphQL client available in React Context.
 */
const GraphQLClientProvider: React.FC<GraphQLClientProviderProps> = ({
  serverUrl,
  options,
  children,
}) => {
  // Set up a new GraphQL client with the provided options
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [client] = useState(new GraphQLClient(serverUrl, options))

  // Make it available to all children via this provider.
  return (
    <clientContext.Provider value={client}>{children}</clientContext.Provider>
  )
}

export default GraphQLClientProvider
