"use client"

import { createContext, useContext } from "react"

export type AllowUnauthenticatedRequestsContext = {
  allowUnauthenticatedRequests: boolean
}

const allowUnauthenticatedRequestsContext =
  createContext<AllowUnauthenticatedRequestsContext>({
    allowUnauthenticatedRequests: false,
  })

export const AllowUnauthenticatedRequestsProvider =
  allowUnauthenticatedRequestsContext.Provider
export const useAllowUnauthenticatedRequests = () =>
  useContext(allowUnauthenticatedRequestsContext)
