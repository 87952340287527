/**
 * ----------------------------------------
 * ☢️ Error handling from GraphQL Endpoints
 * ----------------------------------------
 * This file defines the structure of errors we generally expect to see from the
 * GraphQL endpoint when they happen. Note that some errors won't follow this format.
 * The actual HTTP response, unless something went wrong with GraphQL will be 200.
 * If something went wrong with GraphQL, it will be something other than 200.
 * The semantic HTTP response, i.e. 404 for SpaceNotFound is included in the
 * GraphQLError.extensions.status.
 * In general, anything that is not a 200 actual response code, we probably need
 * to throw a proper error screen because something really bad happened.
 *
 * The location and path fields are not always present, depends how your query
 * is set up using aliases and if it's applicable.
 *
 * If any top-level data item is null in the response, there will be an associated
 * error with that data item. We only return null if there's an error, all our other
 * methods return something, even delete mutations. This enables us to have this
 * practice; also ensuring every query/mutation can be null means queries that don't
 * error still succeed, while others don't!
 *
 * If we've got a 200 response from the server, then we can process the
 * individual errors in the GraphQL response and handle them.
 *
 * Specific examples:
 * https://www.notion.so/daybridge/Exception-Handling-695336bc300f413ca3647b569b968a79
 */

export interface RawGraphQLErrorRequestResponse {
  response: RawGraphQLErrorResponse
  request: RawGraphQLRequest
}

export interface RawGraphQLErrorResponse {
  errors: GraphQLErrorProperties[]
}

export interface RawGraphQLRequest {
  query: string
  variables: Record<string, unknown>
}

export interface GraphQLErrorProperties {
  // `message` is a summary of the error. Same as the message object
  // in the extensions in application errors.
  message: string

  // If an error can be associated to a particular point in the requested
  // GraphQL document, it should contain a list of locations
  locations?: ReadonlyArray<SourceLocation>

  // If an error can be associated to a particular field in the GraphQL result,
  // it _must_ contain an entry with the key `path` that details the path of
  // the response field which experienced the error. This allows clients to
  // identify whether a null result is intentional or caused by a runtime error
  path?: ReadonlyArray<string | number>

  // `extensions` provides a means to use our standard
  // error format structure for any exceptions returned
  // to clients, so they can be be handled correctly.
  extensions: Extensions
}

export interface Extensions {
  // `status` is the HTTP error status code e.g. 500.
  status: number

  // `type` corresponds to an `ErrorType`, primarily used
  // for categorisation of exceptions.
  // TODO: Replace with proper enum when backend gives it
  type: string

  // `code` corresponds to an `ErrorCode`, as defined by
  // individual modules and libraries.
  // TODO: Replace with proper enum when backend gives it
  code?: string

  // `message` is a friendly human-readable description
  // of the exception.
  message: string

  // `params` is an optional object that can be used to
  // include any relevant property values and additional
  // metadata.
  params?: Record<string, unknown>

  // `severity` is an optional `Severity` which can be
  // used by clients to render the error differently
  // e.g. full-screen error, depending on how bad it is.
  // TODO: Replace with proper enum when backend gives it
  severity?: string

  // `help` is an optional URL linking to a help article
  // detailing how to resolve this exception.
  help?: string

  // `trace` is an optional Trace ID that can be used
  // to check exception logs.
  trace?: string
}

// If an error can be associated to a particular point in the requested
// GraphQL document, it should contain a list of locations
export interface SourceLocation {
  line: number
  column: number
}

export class GraphQLError extends Error implements GraphQLErrorProperties {
  public readonly message: string
  public readonly locations?: ReadonlyArray<SourceLocation>
  public readonly path?: ReadonlyArray<string | number>
  public readonly extensions: Extensions

  constructor(properties: GraphQLErrorProperties) {
    super(properties.message)
    this.message = properties.message
    this.locations = properties.locations
    this.path = properties.path
    this.extensions = properties.extensions
  }

  toString(): string {
    return `GraphQLError: ${this.message}`
  }
}
