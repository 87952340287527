import { enUS, enGB, es, fr, de, it, nl, pt } from "date-fns/locale"

export const locales = ["en-US", "en-GB"]
export const betaLocales = ["de", "fr", "es", "it", "nl", "pt"]
export const allLocales = locales.concat(betaLocales)

export enum SupportedLocale {
  "en-US" = "en-US",
  "en-GB" = "en-GB",
  de = "de",
  fr = "fr",
  es = "es",
  it = "it",
  nl = "nl",
  pt = "pt",
}

export const localeToEmojiFlag = (locale: string) => {
  switch (locale) {
    case "en-US":
      return "🇺🇸"
    case "en-GB":
      return "🇬🇧"
    case "de":
      return "🇩🇪"
    case "fr":
      return "🇫🇷"
    case "es":
      return "🇪🇸"
    case "it":
      return "🇮🇹"
    case "nl":
      return "🇳🇱"
    case "pt":
      return "🇵🇹"
    default:
      return locale
  }
}

export const localeToLanguageName = (locale: string) => {
  switch (locale) {
    case "en-US":
      return "English (US)"
    case "en-GB":
      return "English (UK)"
    case "de":
      return "Deutsch"
    case "fr":
      return "Français"
    case "es":
      return "Español"
    case "it":
      return "Italiano"
    case "nl":
      return "Nederlands"
    case "pt":
      return "Português"
    default:
      return locale
  }
}

export const defaultLocale = "en-US"

export const dateFnsLocaleForLocale = (
  locale: keyof typeof SupportedLocale,
) => {
  switch (locale) {
    case "en-US":
      return enUS
    case "en-GB":
      return enGB
    case "de":
      return de
    case "fr":
      return fr
    case "es":
      return es
    case "it":
      return it
    case "nl":
      return nl
    case "pt":
      return pt
    default:
      return enUS
  }
}
