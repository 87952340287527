import { cn } from "@daybridge/cn"
import React from "react"

interface AvatarStackProps {
  size?: number
  children: React.ReactNode
  className?: string
}

const AvatarStackFn = React.forwardRef(
  (props: AvatarStackProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { size, children, className, ...rest } = props
    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-row",
          "[&>span>span]:border-2 [&>span>img]:border-2 ",
          "[&>span>span]:border-surface [&>span>img]:border-surface",
          "[&>span]:mr-[calc(var(--avatar-size)_*_-0.25)]",
          "mr-[calc(var(--avatar-stack-size)_*_0.25)]",
          className,
        )}
        style={
          {
            "--avatar-stack": "1",
            "--avatar-stack-size": `${size || 64}px`,
          } as React.CSSProperties
        }
        {...rest}
      >
        {children}
      </div>
    )
  },
)
AvatarStackFn.displayName = "AvatarStack"

export const AvatarStack = React.memo(AvatarStackFn) as typeof AvatarStackFn
