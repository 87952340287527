import { DateTime } from "luxon"

export enum DayOfWeek {
  Friday = "friday",
  Monday = "monday",
  Saturday = "saturday",
  Sunday = "sunday",
  Thursday = "thursday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
}

const weekStartMap: Record<DayOfWeek, number> = {
  [DayOfWeek.Sunday]: 6,
  [DayOfWeek.Monday]: 0,
  [DayOfWeek.Tuesday]: 1,
  [DayOfWeek.Wednesday]: 2,
  [DayOfWeek.Thursday]: 3,
  [DayOfWeek.Friday]: 4,
  [DayOfWeek.Saturday]: 5,
}

export const startOfWeek = (date: DateTime, weekStart: DayOfWeek) => {
  const luxonWeekday = date.weekday - 1
  const weekStartOffset = weekStartMap[weekStart]
  // Compute wrapping difference in days to get to the start of the week
  const diff = (luxonWeekday - weekStartOffset + 7) % 7

  return date.startOf("day").minus({ days: diff })
}

export const isStartOfWeek = (date: DateTime, weekStart: DayOfWeek) => {
  return date.weekday - 1 === weekStartMap[weekStart]
}
